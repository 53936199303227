<template>
  <div class="container">
    <div class="text-center" :class="{['lg:text-left']: $root.state !== 'live'}">
      <h1 class="mb-4"><GrecaleLogo class="grecale"/></h1>
      <h2 class="mx-auto mb-8" :class="`lang-${$language.current}`" v-if="title">{{title}}</h2>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import GrecaleLogo from '@/assets/img/grecale_logo.svg'

export default {
  name: 'LogoAndContent',
  components: { GrecaleLogo },
  props: {
    title: { type: String }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  @screen beforeSm {
    &.lang-it {
      max-width: rem(280px);
    }

    &.lang-en {
      max-width: rem(250px);
    }

    &.lang-es {
      max-width: rem(300px);
    }
  }

}

html.mobile {
  @media (orientation: landscape) {
    h1 {
      margin-bottom: rem(4px);
    }

    h2 {
      max-width: 100%;

      margin-bottom: rem(12px);

      font-size: rem(16px);
    }

    .grecale {
      max-width: rem(150px);
    }
  }
}
</style>
